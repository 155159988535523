import type { Snapshot } from '@ember-data/store';
import type {
  ResourceObject,
  ResponseWithData,
} from 'ember-smily-base/utils/json-api';
import { isNew } from 'ember-smily-base/utils/serializer';
import ApplicationSerializer from 'smily-admin-ui/serializers/application';

export default class PaymentSerializer extends ApplicationSerializer<'payment'> {
  serialize(snapshot: Snapshot, options?: {}) {
    const json = super.serialize(
      snapshot,
      options,
    ) as ResponseWithData<ResourceObject>;

    if (isNew(snapshot, options)) {
      delete json.data.attributes!['refund-amount'];

      const booking = json.data.relationships?.booking;

      delete json.data.relationships;

      if (booking) {
        json.data.relationships = {
          booking,
        };
      }
    }

    return json;
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    payment: PaymentSerializer;
  }
}
